<template>
  <div>
    <ul class="list-unstyled">
      <li>• Minimum password length shall be 10 characters.</li>
      <li>• Password shall be comprised of case sensitive alphabets, numeric letters, and special characters.</li>
      <li>• Password shall include at least one numeric letter and one special character.</li>
      <li>• Password shall not be guessable or include dictionary words.</li>
      <li>• User accounts shall get locked on 5 consecutive wrong attempts and shall get unlocked automatically after 15 minutes.</li>
      <li>• Last 3 passwords shall not be reused.</li>
      <li> • Password shall periodically be expired after every 90 days.</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'privacyPolicy',
}
</script>

<style scoped>

</style>
